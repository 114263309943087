import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import TagsList from "../components/widget/TagsList"
import { NewsletterBanner } from "../components/NewsletterBanner"

const TagsPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Manu Sobles `;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={"Posts tags"}
        pathName={`/tags`}
        article={false}
      />

      <section
        className="page"
        itemScope
      >
        <header className="max-w-screen-sm mx-auto mt-12 mb-10">
          <h1 itemProp="headline">Tags</h1>
        </header>

        <section itemProp="pageBody" className="max-w-screen-sm mx-auto mb-6">
          <TagsList tagsGroup={data.tagsGroup} />
        </section>

        <NewsletterBanner />

        <p className="max-w-screen-sm mx-auto mt-16 mb-12">
          <Link to="/" className="button">Go back home</Link>
        </p>

      </section>
    </Layout>
  )
}

TagsPage.propTypes = {
  data: PropTypes.shape({
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
    tagsGroup: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
  }),
}

export default TagsPage

export const pageQuery = graphql`
  query TagsPage {
    site {
      siteMetadata {
        title
      }
    }
    tagsGroup: allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`